/*******
Copyright Topuphome (pty) ltd, 2022
*******/
@media screen and (max-width: 1280px) {
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 300px;
        margin: 20px auto;
    }
    .hor-meud-ncbvdvdf {
        width: 400px;
    }
}

@media screen and (max-width: 1024px) {
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 300px;
        margin: 20px auto;
    }
    .hor-meud-ncbvdvdf {
        width: 400px;
    }
}

@media screen and (max-width: 960px) {
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 300px;
        margin: 20px auto;
    }
    .hor-meud-ncbvdvdf {
        width: 400px;
    }
}

@media screen and (max-width: 800px) {
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 300px;
        margin: 20px auto;
    }
    .hor-meud-ncbvdvdf {
        width: 400px;
    }
}

@media screen and (max-width: 812px) {
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 300px;
        margin: 20px auto;
    }
    .hor-meud-ncbvdvdf {
        width: 400px;
    }
}

@media screen and (max-width: 768px) {
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 300px;
        margin: 20px auto;
    }
    .hor-meud-ncbvdvdf {
        width: 400px;
    }
}

@media screen and (max-width: 740px) {
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 300px;
        margin: 20px auto;
    }
    .hor-meud-ncbvdvdf {
        width: 400px;
    }
}

@media screen and (max-width: 736px) {
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 300px;
        margin: 20px auto;
    }
    .hor-meud-ncbvdvdf {
        width: 400px;
    }
}

@media screen and (max-width: 732px) {
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 300px;
        margin: 20px auto;
    }
    .hor-meud-ncbvdvdf {
        width: 400px;
    }
}

@media screen and (max-width: 667px) {
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 300px;
        margin: 20px auto;
    }
    .hor-meud-ncbvdvdf {
        width: 400px;
    }
}

@media screen and (max-width: 640px) {
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 300px;
        margin: 20px auto;
    }
    .hor-meud-ncbvdvdf {
        width: 400px;
    }
}

@media screen and (max-width: 600px) {
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 300px;
        margin: 20px auto;
    }
    .hor-meud-ncbvdvdf {
        width: 400px;
    }
    .width-100px {
        width: auto;
        padding-left: 5px;
    }
    .srvc-balances {
        width: 90%!important;
    }
    .service-data-row {
        width: 90%!important;
        grid-template-columns: 40% auto;
    }
    .service-data-row h3 {
        font-size: 14px;
    }
    .sys-ntc {
        width: 90%!important;
    }
    .system-notices {
        width: 95%!important;
    }
    .system-notices .service-data-row {
        width: 100%!important;
    }
    .system-notices .service-data-row-right select {
        font-size: 0.8rem;
    }
    .system-notices .service-data-row-right .input-theme-hfhg {
        font-size: 0.8rem;
        width: 100%!important;
    }
    .hist-btn button {
        font-size: 0.8rem;
    }
    .n-j-g {
        grid-template-columns: 100%;
        grid-template-rows: 300px 300px;
        grid-row-gap: 10px;
    }
    .jkdjs-ldkdj,.ksks-ldkdj {
        width: 280px;
    }
    .ksos-pwjs {
        font-size: 14px;
    }
    .kd-peie h3 {
        font-size: 12px;
        width: 80%;
        margin: auto;
    }
    .snslpws {
        font-size: 12px;
    }
    .sjs-pwzm {
        font-size: 14px;
    }
    .kjss-pdkd {
        width: 90%;
    }
    .mshs-kkdlkd {
        text-align: center;
    }
    .kdjdjd-ldd {
        height: auto!important;
    }
}

@media screen and (max-width: 414px) {
    .login-form {
        width: 340px;
        height: 312px;
        top: calc((100% - 312px)/2.0);
        left: calc((100% - 340px)/2.0);
    }
    #side-popin {
        width: 360px;
        padding: 4px;
        position: fixed;
        right: 2px;
        top: 30px;
    }
    .the-amount-input {
        width: 200px;
    }
    #gfgg-jfhfh {
        width: 100%;
    }
    .container {
        font-size: 16px;
    }
    .add-merchant-action {
        width: 90%;
        min-height: 500px;
    }
    .trzct-form-action {
        width: 90%;
    }
    .confirmation {
        min-height: 350px;
    }
    .hdysduguugdjg {
        width: 80%;
    }
    .merchant-hfgshs {
        width: 100%;
    }
    .data-row-info {
        width: 100%;
    }
    .staff-actions-gdfsgs {
        margin: 10px;
    }
    .acjfhfj-cont {
        justify-content: center;
    }
    .tranzact-trxs-jhfd td, .tranzact-trxs-jhfd th {
        font-size: 12px;
        padding: 1px;
    }
    .the-simple-hdr {
        width: 90%;
        grid-template-columns: 100%;
        grid-auto-rows: 30px 30px 30px;
        border-radius: 1rem;
        padding: 10px;
    }
    .the-simple-hdr div p {
        font-size: 14px;
    }
    .lgout-btn-home {
        text-align: center;
    }
    .what-woild-you {
        font-size: 20px;
    }
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 90%;
        margin: 20px auto;
    }
    .fatalError {
        width: 90%;
        left: calc((100% - 90%)/2);
    }
    .text-area-bgd {
        width: 230px;
    }
    .hide-on-small-screen-trx-id {
        display: none;
    }
    .hor-meud-ncbvdvdf {
        width: 360px;
    }
    .the-hdgdg-show {
        width: 90%;
        left: calc((100% - 90%)/2.0);
    }
    .hfgfhfh-mvhfgf {
        width: 95%;
    }
    .search-util-mvndh {
        grid-template-columns: 80% 20%;
    }
    .on-view-merchats {
        width: 90%!important;
    }
    .width-100px {
        width: auto;
        padding-left: 5px;
    }
    .srvc-balances {
        width: 90%!important;
    }
    .service-data-row {
        width: 90%!important;
        grid-template-columns: 40% auto;
    }
    .service-data-row h3 {
        font-size: 14px;
    }
    .sys-ntc {
        width: 90%!important;
    }
    .system-notices {
        width: 95%!important;
    }
    .system-notices .service-data-row {
        width: 100%!important;
    }
    .system-notices .service-data-row-right select {
        font-size: 0.8rem;
    }
    .system-notices .service-data-row-right .input-theme-hfhg {
        font-size: 0.8rem;
        width: 100%!important;
    }
    .hist-btn button {
        font-size: 0.8rem;
    }
    .n-j-g {
        grid-template-columns: 100%;
        grid-template-rows: 300px 300px;
        grid-row-gap: 10px;
    }
    .jkdjs-ldkdj,.ksks-ldkdj {
        width: 280px;
    }
    .ksos-pwjs {
        font-size: 14px;
    }
    .kd-peie h3 {
        font-size: 12px;
        width: 80%;
        margin: auto;
    }
    .snslpws {
        font-size: 12px;
    }
    .sjs-pwzm {
        font-size: 14px;
    }
    .kjss-pdkd {
        width: 90%;
    }
    .mshs-kkdlkd {
        text-align: center;
    }
    .kdjdjd-ldd {
        height: auto!important;
    }
}

@media screen and (max-width: 412px) {
    .login-form {
        width: 340px;
        height: 312px;
        top: calc((100% - 312px)/2.0);
        left: calc((100% - 340px)/2.0);
    }
    #side-popin {
        width: 350px;
        padding: 4px;
        position: fixed;
        right: 2px;
        top: 30px;
    }
    .the-amount-input {
        width: 200px;
    }
    #gfgg-jfhfh {
        width: 100%;
    }
    .container {
        font-size: 16px;
    }
    .add-merchant-action {
        width: 90%;
        min-height: 500px;
    }
    .trzct-form-action {
        width: 90%;
    }
    .confirmation {
        min-height: 350px;
    }
    .hdysduguugdjg {
        width: 80%;
    }
    .merchant-hfgshs {
        width: 100%;
    }
    .data-row-info {
        width: 100%;
    }
    .staff-actions-gdfsgs {
        margin: 10px;
    }
    .acjfhfj-cont {
        justify-content: center;
    }
    .tranzact-trxs-jhfd td, .tranzact-trxs-jhfd th {
        font-size: 12px;
        padding: 1px;
    }
    .gfgfg-total-nfhfh h4 {
        font-size: 12px;
    }
    .the-simple-hdr {
        width: 90%;
        grid-template-columns: 100%;
        grid-auto-rows: 30px 30px 30px;
        border-radius: 1rem;
        padding: 10px;
    }
    .the-simple-hdr div p {
        font-size: 14px;
    }
    .lgout-btn-home {
        text-align: center;
    }
    .what-woild-you {
        font-size: 20px;
    }
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 90%;
        margin: 20px auto;
    }
    .jjd-sjsh {
        width: 80%;
    }
    .fatalError {
        width: 90%;
        left: calc((100% - 90%)/2);
    }
    .text-area-bgd {
        width: 230px;
    }
    .hide-on-small-screen-trx-id {
        display: none;
    }
    .hor-meud-ncbvdvdf {
        width: 360px;
    }
    .the-hdgdg-show {
        width: 90%;
        left: calc((100% - 90%)/2.0);
    }
    .hfgfhfh-mvhfgf {
        width: 95%;
    }
    .search-util-mvndh {
        grid-template-columns: 80% 20%;
    }
    .on-view-merchats {
        width: 90%!important;
    }
    .width-100px {
        width: auto;
        padding-left: 5px;
    }
    .srvc-balances {
        width: 90%!important;
    }
    .service-data-row {
        width: 90%!important;
        grid-template-columns: 40% auto;
    }
    .service-data-row h3 {
        font-size: 14px;
    }
    .sys-ntc {
        width: 90%!important;
    }
    .system-notices {
        width: 95%!important;
    }
    .system-notices .service-data-row {
        width: 100%!important;
    }
    .system-notices .service-data-row-right select {
        font-size: 0.8rem;
    }
    .system-notices .service-data-row-right .input-theme-hfhg {
        font-size: 0.8rem;
        width: 100%!important;
    }
    .hist-btn button {
        font-size: 0.8rem;
    }
    .n-j-g {
        grid-template-columns: 100%;
        grid-template-rows: 300px 300px;
        grid-row-gap: 10px;
    }
    .jkdjs-ldkdj,.ksks-ldkdj {
        width: 280px;
    }
    .ksos-pwjs {
        font-size: 14px;
    }
    .kd-peie h3 {
        font-size: 12px;
        width: 80%;
        margin: auto;
    }
    .snslpws {
        font-size: 12px;
    }
    .sjs-pwzm {
        font-size: 14px;
    }
    .kjss-pdkd {
        width: 90%;
    }
    .mshs-kkdlkd {
        text-align: center;
    }
    .kdjdjd-ldd {
        height: auto!important;
    }
}

@media screen and (max-width: 375px) {
    .login-form {
        width: 340px;
        height: 312px;
        top: calc((100% - 312px)/2.0);
        left: calc((100% - 340px)/2.0);
    }
    #side-popin {
        width: 300px;
        padding: 4px;
        position: fixed;
        right: 2px;
        top: 30px;
    }
    .the-amount-input {
        width: 200px;
    }
    #gfgg-jfhfh {
        width: 100%;
    }
    .container {
        font-size: 16px;
    }
    .add-merchant-action {
        width: 90%;
        min-height: 500px;
    }
    .trzct-form-action {
        width: 90%;
    }
    .confirmation {
        min-height: 350px;
    }
    .hdysduguugdjg {
        width: 80%;
    }
    .merchant-hfgshs {
        width: 100%;
    }
    .data-row-info {
        width: 100%;
    }
    .staff-actions-gdfsgs {
        margin: 10px;
    }
    .acjfhfj-cont {
        justify-content: center;
    }
    .tranzact-trxs-jhfd td, .tranzact-trxs-jhfd th {
        font-size: 12px;
        padding: 1px;
    }
    .gfgfg-total-nfhfh h4 {
        font-size: 12px;
    }
    .the-simple-hdr {
        width: 90%;
        grid-template-columns: 100%;
        grid-auto-rows: 30px 30px 30px;
        border-radius: 1rem;
        padding: 10px;
    }
    .the-simple-hdr div p {
        font-size: 14px;
    }
    .lgout-btn-home {
        text-align: center;
    }
    .what-woild-you {
        font-size: 20px;
    }
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 90%;
        margin: 20px auto;
    }
    .jjd-sjsh {
        width: 80%;
    }
    .fatalError {
        width: 90%;
        left: calc((100% - 90%)/2);
    }
    .text-area-bgd {
        width: 230px;
    }
    .hide-on-small-screen-trx-id {
        display: none;
    }
    .hor-meud-ncbvdvdf {
        width: 350px;
    }
    .the-hdgdg-show {
        width: 90%;
        left: calc((100% - 90%)/2.0);
    }
    .hfgfhfh-mvhfgf {
        width: 95%;
    }
    .search-util-mvndh {
        grid-template-columns: 80% 20%;
    }
    .on-view-merchats {
        width: 90%!important;
    }
    .width-100px {
        width: auto;
        padding-left: 5px;
    }
    .srvc-balances {
        width: 90%!important;
    }
    .service-data-row {
        width: 90%!important;
        grid-template-columns: 40% auto;
    }
    .service-data-row h3 {
        font-size: 14px;
    }
    .sys-ntc {
        width: 90%!important;
    }
    .system-notices {
        width: 95%!important;
    }
    .system-notices .service-data-row {
        width: 100%!important;
    }
    .system-notices .service-data-row-right select {
        font-size: 0.8rem;
    }
    .system-notices .service-data-row-right .input-theme-hfhg {
        font-size: 0.8rem;
        width: 100%!important;
    }
    .hist-btn button {
        font-size: 0.8rem;
    }
    .n-j-g {
        grid-template-columns: 100%;
        grid-template-rows: 300px 300px;
        grid-row-gap: 10px;
    }
    .jkdjs-ldkdj,.ksks-ldkdj {
        width: 280px;
    }
    .ksos-pwjs {
        font-size: 14px;
    }
    .kd-peie h3 {
        font-size: 12px;
        width: 80%;
        margin: auto;
    }
    .snslpws {
        font-size: 12px;
    }
    .sjs-pwzm {
        font-size: 14px;
    }
    .kjss-pdkd {
        width: 90%;
    }
    .mshs-kkdlkd {
        text-align: center;
    }
    .kdjdjd-ldd {
        height: auto!important;
    }
}

@media screen and (max-width: 360px) {
    .login-form {
        width: 340px;
        height: 312px;
        top: calc((100% - 312px)/2.0);
        left: calc((100% - 340px)/2.0);
    }
    #side-popin {
        width: 300px;
        padding: 4px;
        position: fixed;
        right: 2px;
        top: 30px;
    }
    .the-amount-input {
        width: 200px;
    }
    #gfgg-jfhfh {
        width: 100%;
    }
    .container {
        font-size: 16px;
    }
    .add-merchant-action {
        width: 90%;
        min-height: 500px;
    }
    .trzct-form-action {
        width: 90%;
    }
    .confirmation {
        min-height: 350px;
    }
    .hdysduguugdjg {
        width: 80%;
    }
    .merchant-hfgshs {
        width: 100%;
    }
    .data-row-info {
        width: 100%;
    }
    .staff-actions-gdfsgs {
        margin: 10px;
    }
    .acjfhfj-cont {
        justify-content: center;
    }
    .tranzact-trxs-jhfd td, .tranzact-trxs-jhfd th {
        font-size: 12px;
        padding: 1px;
    }
    .gfgfg-total-nfhfh h4 {
        font-size: 12px;
    }
    .the-simple-hdr {
        width: 90%;
        grid-template-columns: 100%;
        grid-auto-rows: 30px 30px 30px;
        border-radius: 1rem;
        padding: 10px;
    }
    .the-simple-hdr div p {
        font-size: 14px;
    }
    .lgout-btn-home {
        text-align: center;
    }
    .what-woild-you {
        font-size: 20px;
    }
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 90%;
        margin: 20px auto;
    }
    .jjd-sjsh {
        width: 80%;
    }
    .fatalError {
        width: 90%;
        left: calc((100% - 90%)/2);
    }
    .text-area-bgd {
        width: 230px;
    }
    .hide-on-small-screen-trx-id {
        display: none;
    }
    .hor-meud-ncbvdvdf {
        width: 350px;
    }
    .the-hdgdg-show {
        width: 90%;
        left: calc((100% - 90%)/2.0);
    }
    .hfgfhfh-mvhfgf {
        width: 95%;
    }
    .search-util-mvndh {
        grid-template-columns: 80% 20%;
    }
    .on-view-merchats {
        width: 90%!important;
    }
    .width-100px {
        width: auto;
        padding-left: 5px;
    }
    .srvc-balances {
        width: 90%!important;
    }
    .service-data-row {
        width: 90%!important;
        grid-template-columns: 40% auto;
    }
    .service-data-row h3 {
        font-size: 14px;
    }
    .sys-ntc {
        width: 90%!important;
    }
    .system-notices {
        width: 95%!important;
    }
    .system-notices .service-data-row {
        width: 100%!important;
    }
    .system-notices .service-data-row-right select {
        font-size: 0.8rem;
    }
    .system-notices .service-data-row-right .input-theme-hfhg {
        font-size: 0.8rem;
        width: 100%!important;
    }
    .hist-btn button {
        font-size: 0.8rem;
    }
    .n-j-g {
        grid-template-columns: 100%;
        grid-template-rows: 300px 300px;
        grid-row-gap: 10px;
    }
    .jkdjs-ldkdj,.ksks-ldkdj {
        width: 280px;
    }
    .ksos-pwjs {
        font-size: 14px;
    }
    .kd-peie h3 {
        font-size: 12px;
        width: 80%;
        margin: auto;
    }
    .snslpws {
        font-size: 12px;
    }
    .sjs-pwzm {
        font-size: 14px;
    }
    .kjss-pdkd {
        width: 90%;
    }
    .mshs-kkdlkd {
        text-align: center;
    }
    .kdjdjd-ldd {
        height: auto!important;
    }
}

@media screen and (max-width: 320px) {
    .login-form {
        width: 300px;
        height: 200px;
        top: calc((100% - 200px)/2.0);
        left: calc((100% - 300px)/2.0);
    }
    #side-popin {
        width: 290px;
        padding: 4px;
        position: fixed;
        right: 2px;
        top: 30px;
    }
    .the-amount-input {
        width: 200px;
    }
    #gfgg-jfhfh {
        width: 100%;
    }
    .container {
        font-size: 16px;
    }
    .add-merchant-action {
        width: 90%;
        min-height: 500px;
    }
    .trzct-form-action {
        width: 90%;
    }
    .confirmation {
        min-height: 350px;
    }
    .hdysduguugdjg {
        width: 80%;
    }
    .merchant-hfgshs {
        width: 100%;
    }
    .data-row-info {
        width: 100%;
    }
    .staff-actions-gdfsgs {
        margin: 10px;
    }
    .acjfhfj-cont {
        justify-content: center;
    }
    .tranzact-trxs-jhfd td, .tranzact-trxs-jhfd th {
        font-size: 12px;
        padding: 1px;
    }
    .gfgfg-total-nfhfh h4 {
        font-size: 12px;
    }
    .the-simple-hdr {
        width: 90%;
        grid-template-columns: 100%;
        grid-auto-rows: 30px 30px 30px;
        border-radius: 1rem;
        padding: 10px;
    }
    .the-simple-hdr div p {
        font-size: 14px;
    }
    .lgout-btn-home {
        text-align: center;
    }
    .what-woild-you {
        font-size: 20px;
    }
    .filters-ncbhjsgs,.periods-ncbsidg {
        width: 90%;
        margin: 20px auto;
    }
    .jjd-sjsh {
        width: 80%;
    }
    .fatalError {
        width: 90%;
        left: calc((100% - 90%)/2);
    }
    .text-area-bgd {
        width: 210px;
    }
    .hide-on-small-screen-trx-id {
        display: none;
    }
    .hor-meud-ncbvdvdf {
        width: 300px;
    }
    .the-hdgdg-show {
        width: 90%;
        left: calc((100% - 90%)/2.0);
    }
    .hfgfhfh-mvhfgf {
        width: 95%;
    }
    .search-util-mvndh {
        grid-template-columns: 80% 20%;
    }
    .on-view-merchats {
        width: 90%!important;
    }
    .width-100px {
        width: auto;
        padding-left: 5px;
    }
    .srvc-balances {
        width: 90%!important;
    }
    .service-data-row {
        width: 90%!important;
        grid-template-columns: 40% auto;
    }
    .service-data-row h3 {
        font-size: 14px;
    }
    .sys-ntc {
        width: 90%!important;
    }
    .system-notices {
        width: 95%!important;
    }
    .system-notices .service-data-row {
        width: 100%!important;
    }
    .system-notices .service-data-row-right select {
        font-size: 0.8rem;
    }
    .system-notices .service-data-row-right .input-theme-hfhg {
        font-size: 0.8rem;
        width: 100%!important;
    }
    .hist-btn button {
        font-size: 0.8rem;
    }
    .n-j-g {
        grid-template-columns: 100%;
        grid-template-rows: 300px 300px;
        grid-row-gap: 10px;
    }
    .jkdjs-ldkdj,.ksks-ldkdj {
        width: 280px;
    }
    .ksos-pwjs {
        font-size: 14px;
    }
    .kd-peie h3 {
        font-size: 12px;
        width: 80%;
        margin: auto;
    }
    .snslpws {
        font-size: 12px;
    }
    .sjs-pwzm {
        font-size: 14px;
    }
    .kjss-pdkd {
        width: 90%;
    }
    .mshs-kkdlkd {
        text-align: center;
    }
    .kdjdjd-ldd {
        height: auto!important;
    }
}