#svg {
stroke-dasharray: 300px;
stroke-dashoffset: 0;
animation: beizerBeat 1700ms linear infinite;
transform: rotate(-360deg);}
@keyframes beizerBeat{
50%{stroke-dashoffset: 260px;}
50%{stroke-dashoffset: 200px;}
50%{stroke-dashoffset: -200px;}
100%{transform: rotate(360deg);}}
/**
** Blue color for progress spinner rgb(31, 85, 184)
**/
#progressBar {
  position: fixed;
  text-align: center;
  background-color: #fff;
  z-index: 100;
  left: calc((100% - 110px)/2.0);
  top: calc((100% - 110px)/2.0);
  padding: 10px;
  height: 110px;
  width: 110px;
}
#smartLoader {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
#smartLoader svg {
  width: 100%;
  height: 100%;
}