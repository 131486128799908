@font-face {
    font-family: 'NimbusSans Regular';
    src: url('../assets/fonts/NimbusSansl/NimbusSanL-Reg.otf');
}
html,body,#root {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    background-color: var(--bkgColor);
    font-family: 'NimbusSans Regular', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--white);
    background-image: url('../assets/backgrounds/strips_bkg.png');
    background-size: 100% 100%;
    background-repeat: repeat;
    background-attachment: fixed;
}
body input {
    font-family: 'NimbusSans Regular', sans-serif;
    color: var(--inputTextColor);
}
.home {
    width: 100%;
    height: 100%;
}
.home h1 {
    position: absolute;
    top: 40%;
    left: 40%;
}
.login-form {
    width: 400px;
    min-height: 250px;
    background-color: var(--white);
    position: absolute;
    top: calc((100% - 250px)/2.0);
    left: calc((100% - 400px)/2.0);
}
.input-logo {
    width: 60%;
}
.input-logo img {
    width: 100%;
}
.form-input-content p {
    margin-bottom: 10px;
}
.input-form-the {
    height: 30px;
    width: 100%;
    border-style: none;
    text-align: center;
    border: 1px solid var(--bkgColor);
    font-size: 1rem;
    color: var(--inputTextColor);
}
.need-help-text {
    text-align: right;
}
.btn-login {
    margin-top: 15px!important;
}
#btn-rounded {
    border-radius: 2rem;
}
.error span {
    color: var(--textRed);
    text-align: center;
}
.error {
    text-align: center;
}
#side-popin {
    background-color: var(--white);
    width: 400px;
    min-height: 50px;
    border-radius: .5rem;
    padding: 5px;
    position: fixed;
    right: 5px;
    top: 35px;
    z-index: 100;
    display: grid;
    grid-template-columns: auto 60px;
    grid-template-rows: 100%;
}
#icon-dicvhdh {
    text-align: center;
    border-left: 1px solid var(--smartBlack);
}
#close-popin {
    height: 30px;
    margin-top: 10px;
    cursor: pointer;
}
.fatalError {
    background-color: var(--white);
    position: fixed;
    width: 400px;
    height: 400px;
    left: calc((100% - 400px)/2.0);
    top: calc((100% - 400px)/2.0);
    z-index: 100;
    text-align: center;
}
.fatalError h3, .fatalError p {
    color: var(--textRed);
    padding: 10px;
}
.error-text {
    color: var(--textRed)!important;
    padding: 10px;
    margin: 0!important;
    text-align: center;
    padding-bottom: 0!important;
}
#error-icon-sjsh {
    height: 100px;
    margin-top: 10px;
}
#close-icon-jdhsjsjh {
    width: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
}
.admin-home {
    width: 90%;
    padding-top: 20px;
    background-color: var(--white);
    text-align: center;
}
#temp-hdr {
    color: var(--textColor);
}
.root-on-admin-home {
    background-image: url('../assets/backgrounds/topuphome_triped_word_color.png');
    background-size: auto auto;
    background-attachment: fixed;
}
.staff-actions {
    padding: 10px 0;
}
.the-actions-jmghghg div {
    margin: 5px;
    display: inline-block;
}
#text-dvlsk {
    text-align: center;
}
.action-buttons-hdgfh,.rounded-force-btn {
    border-radius: 2rem!important;
}
.add-merchant-action h3,.add-merchant-action p {
    color: var(--textColor);
    text-align: center;
    padding: 10px;
}
.action-view-container {
    margin-bottom: 50px;
}
.the-simple-hdr div p {
    color: var(--white);
    margin: 0;
    margin-top: 10px;
}
.the-simple-hdr {
    display: grid;
    grid-template-columns: repeat(3,auto);
    grid-template-rows: auto;
    background-image: linear-gradient(var(--sweetPinky),var(--sheetBlue));
    box-sizing: content-box;
}
.what-woild-you {
    font-size: 30px;
    font-weight: 100;
}
.logo-on-home {
    height: 80px!important;
    margin-bottom: 10px;
}
.lgout-btn-home {
    text-align: right;
}
.input-row div p {
    text-align: left;
}
.input-nsgsgsh {
    width: 100%;
    height: 35px;
    border-style: none;
    border: 1px solid var(--bkgColor);
    font-size: 1.313rem;
    box-sizing: border-box;
    padding: 5px;
}
.input-theme-hfhg {
    border-style: none;
    border: 1px solid var(--coolBlue1);
    font-size: 1rem;
    box-sizing: border-box;
    padding: 5px;
    color: var(--coolBlue1);
}
.content-right {
    text-align: right;
}
.error-on-action-form {
    padding-top: 10px;
}
.data-row-info {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    margin: 10px 0;
}
.nbsfdhs {
    text-align: center;
    margin-top: 50px;
}
.bcmsbd {
    font-size: 14px;
}
.data-row-info div p {
    margin: 0;
    padding: 10px;
}
.left-col {
    text-align: right!important;
}
.right-col {
    text-align: left!important;
}
.acc-status-active p {
    color: var(--forestGreenDark);
}
.place-order-form {
    background-color: var(--coolBlue1);
    background-image: linear-gradient(var(--coolBlue1),var(--smartColor4));
    padding: 10px;
}
.place-order-form p,.place-order-form h3 {
    color: var(--white);
    padding: 0;
}
.the-amount-input {
    height: 30px;
    border-style: none;
    text-align: center;
    color: var(--pinkyColor);
    font-weight: 700;
}
.place-order-button {
    margin: 20px auto;
}
.error-on-maof {
    background-color: var(--white);
    padding: 10px;
    width: 70%;
    margin: 10px auto;
}
.confirmation {
    background-color: var(--smartDarky2);
    background-image: linear-gradient(var(--smartDarky2),var(--smartDarky3));
    width: 70%;
    min-height: 300px;
    position: fixed;
    top: 100px;
    left: calc((100% - 70%)/2.0);
    z-index: 5;
    padding: 5px 10px 30px 10px!important;
}
.confirm-activate-order {
    width: 80%;
    margin: 0 auto;
}
.hfdgfhsdjh {
    color: var(--pinkyColor);
}
#cancel-btn-to-left {
    background-color: var(--bkgRed);
}
#confirm-btn-to-right {
    background-color: var(--forestGreen);
    color: var(--white);
}
.confm-btns {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    margin-top: 20px;
}
.confirm-activate-order p, .confirm-activate-order h3 {
    color: var(--white);
}
.acjfhfj-cont {
    display: flex;
    flex-wrap: wrap;
}
.hdysduguugdjg {
    padding: 20px;
}
.mjhsghfjsgfj {
    text-align: center;
}
.hdygdhgfdgfd {
    text-align: right!important;
}
.settlements-options {
    width: 90%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 30px;
    padding: 5px;
}
#gfgg-jfhfh {
    margin-top: 15px;
    border-radius: 1rem;
    border: 1px solid var(--smartColor8);
}
.settlements-options div p {
    margin: 0;
}

/******Copied from W3Schools*****/
/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    /* Hide the browser's default radio button */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

    /* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

    /* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #ccc;
}

    /* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #2196F3;
}

    /* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

    /* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

    /* Style the indicator (dot/circle) */
.container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}
.select-input-row {
    height: 35px;
    text-align: center;
    font-size: 20px;
    border-style: none;
}
.dsffjdjfjdgfhd {
    margin-top: 10px;
}
.data-row-info-ss div p {
    margin: 0;
    color: var(--white);
}
.data-row-info-ss {
    margin-top: 20px;
    background-color: var(--smartColor1);
    background-image: linear-gradient(var(--smartColor1),var(--smartColor2));
}
.quick-stats-home {
    margin-top: 20px!important;
    background-color: var(--smarColor3);
    margin-bottom: 50px!important;
    padding-top: 1px;
    padding-bottom: 30px;
    text-align: center;
}
.quick-stats-home p, .quick-stats-home h1,.quick-stats-home h2, .quick-stats-home h3 .quick-stats-home h4 {
    color: var(--white);
}
.trzct-daily {
    width: 90%;
    background-color: var(--smartColor4);
    background-image: linear-gradient(var(--smartColor4),var(--smartColor5));
}
#hfgf-title h3 {
    margin: 0;
}
#hfgf-title {
    height: 30px;
    padding-top: 10px;
    background-color: var(--smartColor6);
    background-image: linear-gradient(var(--smartColor6),var(--smartColor7));
}
#jdhdg-body {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 150px;
}
#hdgdhh-title h2 {
    font-size: 20px;
    color: var(--pinkyColor);
}
.ueeyey-text h2 {
    font-size: 18px;
    color: var(--smartColor8);
}
#ueeyey-text-jfhfh {
    font-size: 32px;
}
#sales-hfhfh {
    box-sizing: content-box;
    border-right: 1px solid var(--smartColor6);
}
.balance-row {
    background-color: var(--pinkyColor);
    background-image: linear-gradient(var(--pinkyColor),var(--pinkyColorDark));
}
.name-row {
    background-color: var(--smartColor8);
    background-image: linear-gradient(var(--smartColor6),var(--smartColor9));
}
.jldhdghf {
    text-align: center;
}
.mnbnxvxbv {
    width: 100%;
    text-align: center;
}
.home-nav {
    box-sizing: content-box;
    height: 40px;
    position: sticky;
    top: 0;
    text-align: center;
    display: grid;
    grid-template-columns: 20% auto;
    grid-template-rows: 40px;
    z-index: 100;
}
#the-logo {
    height: 100%;
}
.nav-menu {
    text-align: right;
    padding-right: 5px;
    display: grid;
    grid-template-columns: auto 40px;
    grid-template-rows: 40px;
}
.links-mvnvbv {
    padding-top: 12px;
}
.icon-mvnvbv {
    padding-top: 4px;
}
.nav-menu div a {
    text-decoration: none;
    color: var(--smartColor9);
    margin-left: 10px;
}
.action-view-container {
    margin-top: 20px;
}
.tranzact-trxs-jhfd table *,.tranzact-trxs-jhfd h4 {
    color: var(--textColor);
}
.tranzact-trxs-jhfd table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
}
.tranzact-trxs-jhfd table thead {
    background-image: linear-gradient(var(--pinkyColor),var(--pinkyColorDark));
}
.tranzact-trxs-jhfd td, .tranzact-trxs-jhfd th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 3px;
}
.tranzact-trxs-jhfd th {
    border: 1px solid var(--pinkyColor);
    color: var(--white);
    position: sticky;
    top: 0;
    background-image: linear-gradient(var(--pinkyColor),var(--pinkyColorDark));
}
.tranzact-trxs-jhfd tr:nth-child(even) {
    background-color: #dddddd;
}
.tranzact-trxs-jhfd {
    margin-top: 10px;
}
.filters-ncbhjsgs {
    background-image: linear-gradient(var(--smartColor7),var(--smartColor9));
    box-sizing: content-box;
    padding: 4px;
}
.filters-ncbhjsgs p,.periods-ncbsidg p {
    color: var(--white);
    font-weight: 900;
    margin: 5px;
}
.the-select-fgdhs select,.periods-ncbsidg select {
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 20px;
    border-style: none;
}
.periods-ncbsidg {
    margin-top: 10px;
    background-image: linear-gradient(var(--coolBlue1),var(--coolBlue2));
    box-sizing: content-box;
    padding: 4px;
}
.gfgfg-total-nfhfh {
    background-image: linear-gradient(var(--smartColor7),var(--smartColor9));
    box-sizing: content-box;
    margin-top: 30px;
    padding: 5px;
}
.gfgfg-total-nfhfh h4 {
    margin: 4px;
    color: var(--white);
    text-align: center;
}
.total-ncbcd {
    color: var(--smartColor8)!important;
}
.total-ncbcd-mfj {
    color: var(--smartColor7)!important;
}
.total-ncbcd-trsbs {
    color: var(--pinkyColor);
}
.fhdhfg-tablencgd {
    max-height: 400px;
    overflow-y: scroll;
    position: relative;
}
.nchdhgdghdgh {
    padding-bottom: 30px;
}
.bcvdh-date {
    color: var(--smartColor6);
}
.bcvndbd {
    margin-top: 20px!important;
}
.filters-ncbhjsgs,.periods-ncbsidg {
    width: 300px;
    margin: 20px auto;
}
.nvbvjdgd-jdhdb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.jjd-sjsh {
    margin: 10px;
}
.merchant-hfgshs {
    width: 100%;
}
.bcvdhdg {
    margin: 10px auto;
}
.un-settled-bcvsbsv {
    background-image: linear-gradient(var(--textRed),var(--textRedDark));
}
.settled-bcvsbsv {
    background-image: linear-gradient(var(--forestGreen),var(--forestGreenDark));
}
.settled-td0ncbvb {
    background-image: linear-gradient(var(--forestGreen),var(--forestGreenDark));
    color: var(--white)!important;
}
.un-settled-td0ncbvb {
    background-image: linear-gradient(var(--textRed),var(--textRedDark));
    color: var(--white)!important;
}
#menu-icons-nvhfh {
    height: 30px;
}
.menu-links-mncbcvs {
    max-height: 400px;
    overflow-y: scroll;
    padding-bottom: 10px;
}
.hor-meud-ncbvdvdf {
    background-image: linear-gradient(var(--smartColor6),var(--smartColor7));
    position: fixed;
    top: 45px;
    width: 400px;
    z-index: 9999;
    right: 2px;
    text-align: center;
}
.mfnfkgsgfujfuj-bfg {
    margin: 10px auto!important;
    background-image: linear-gradient(var(--glass1),var(--glass2));
    opacity: .99;
}
.mfnfkgsgfujfuj-bfg h3 {
    margin: 0;
    padding: 10px;
}
.mcbvbf-mnfhf h4 {
    padding-top: 20px;
    padding-bottom: 10px;
    margin: 0;
}
.mcbvbf-mnfhf {
    background-image: linear-gradient(var(--smartDarky3),var(--smartDarky2));
}
.bvgfh-djfh {
    margin-bottom: 20px;
}
.pf-sts-ok {
    height: 20px;
}
.text-area-bgd {
    width: 300px;
    padding: 10px;
    min-height: 50px;
    font-size: 18px!important;
    color: var(--textColor);
    font-family: 'NimbusSans Regular', sans-serif;
    text-align: center;
}
.hdgdg-mnbfhfh {
    margin-bottom: 10px;
}
.error {
    color: var(--textRed)!important;
}
.not-active-seller {
    background-image: linear-gradient(var(--bkgRed),var(--textRedDark));
}
.not-active-seller p {
    color: var(--white);
}
.hfhfh-totla {
    color: var(--textColor);
}
.the-hdgdg-show {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 60px auto;
    position: fixed;
    width: 60%;
    z-index: 1;
    top: 100px;
    left: calc((100% - 60%)/2.0);
    background-image: linear-gradient(var(--smartColor6),var(--smartColor7));
}
#nvbf-close-fjfj {
    width: 40px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.bvbjd {
    color: var(--white)!important;
}
.merchants-mujbcgd {
    max-height: 600px;
    overflow-y: scroll;
    margin-top: 15px;
}
.search-util-mvndh {
    height: 30px;
    margin-top: 10px!important;
    display: grid;
    grid-template-columns: 90% 10%;
    grid-template-rows: 100%;
}
.input-standard-x {
    width: 100%;
    height: 90%;
    border-style: none;
    text-align: center;
    font-size: 18px;
}
.icon-hfhfg {
    background-image: linear-gradient(var(--smartColor6),var(--smartColor7));
}
#search-mvhfhg-icon {
    width: 30px;
}
.icon-hfhfg {
    text-align: center;
}
.trx-clicked {
    background-image: linear-gradient(var(--smartColor6),var(--smartColor7));
}
.trx-clicked td,.trx-clicked td b,.trx-clicked .total-ncbcd-mfj {
    color: var(--white)!important;
}
.vnvbfhd-djd {
    margin-bottom: 50px;
}
.nvbfhfj-msh {
    padding-bottom: 40px;
    margin-bottom: 50px;
}
.text-area {
    min-height: 80px;
    color: var(--sheetBlue);
}
.content-centred {
    text-align: center;
}
.small-loader-nvfgd {
    width: 150px;
    margin-top: 10px!important;
}
.sm-lfjfgfjg {
    width: 100%;
}
.error p {
    color: var(--bkgRed);
}
.bvv-fhf {
    width: 100px;
}
.rounded-circle {
    border-radius: 4rem;
}
.bvv-fhf a {
    text-decoration: none;
    padding: 10px;
    color: var(--white);
    transition: color 500ms;
    display: block;
}
.hfhf-btn {
    margin-top: 10px !important;
}
.hfhf-btn h3 {
    color: var(--white);
    padding: 8px;
}
.bvv-fhf a:hover {
    color: var(--yellowBottom);
}
.not-found div h2 {
    margin: 0;
    color: var(--white);
    font-size: 4.500rem;
}
.the-content-not-found h3, .the-content-not-found p {
    color: var(--white);
}
#confuded-robot-nvh {
    width: 100px;
    transition-duration: 1s;
}
#confuded-robot-nvh:hover {
    transform: rotate(360deg);
}
.not-found {
    background-image: url('../assets/backgrounds/strips_bkg.png'),url('../assets/backgrounds/nova_bkg.png'),url('../assets/backgrounds/topuphome_triped_word.png'),linear-gradient(var(--tuhTop),var(--tuhBottom));
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    text-align: center;
    position: relative;
    padding-top: 100px;
    box-sizing: content-box;
    background-attachment: fixed;
}
.inv-dgdg {
    box-sizing: border-box;
    padding-bottom: 30px;
    margin-bottom: 20px;
}
.srvc-balances {
    background-image: url('../assets/backgrounds/strips_bkg.png'),linear-gradient(var(--coolBlue1),var(--smartDarky3));
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 30px;
    position: relative;
}
.service-data-row {
    background-image: linear-gradient(var(--rowGlassTop2),var(--rowGlassBottom2));
    background: var(--rowGlassTop);
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    height: 60px;
    margin-top: 20px!important;
    background-image: linear-gradient(var(--smartColor6),var(--smartColor7));
}
.service-hghgg {
    box-sizing: border-box;
    padding: 10px;
}
.service-data-row-left {
    box-sizing: border-box;
}
.service-data-row-left h3 {
    text-align: right!important;
    margin-top: 10px;
    color: var(--white2);
}
.service-data-row-right h3 {
    text-align: left;
    margin-top: 10px;
    color: var(--white2);
}
.bdr-srvc-row {
    border: 4px solid var(--rowGlassTop2);
}
.trzct-daily {
    box-sizing: border-box;
    border: 1px solid var(--smartColor6);
}
.select-row-nhfh {
    width: 100%!important;
    height: 100%;
}
.nvbfg0jfhfh {
    width: 200px;
    height: 40px;
    font-weight: 900;
    text-align: center;
}
.jfh-yrt-xvdf {
    margin-top: 0;
    padding-top: 10px;
    text-align: right!important;
    color: var(--white)!important;
}
.mbfhd-mfsf {
    text-align: right;
    box-sizing: border-box;
}
.ndbdg-mhd {
    height: auto;
}
.the-editor-nghfh {
    margin-top: 10px;
}
.nvhfhfhf-mcjhf {
    height: 60px;
    margin-top: 20px !important;
    background-image: linear-gradient(var(--smartColor6),var(--smartColor7));
}
.input-subjdh {
    height: 30px;
    width: 99%;
    text-align: center;
    font-size: 18px;
}
.bfgfg-btnd-mfhfh {
    text-align: left;
}
.single-merchant .left-col p {
    text-align: right;
    padding-right: 20px!important;
}
.single-merchant .right-col p {
    text-align: left!important;
    padding-left: 20px!important;
}
.hist-btn {
    box-sizing: border-box;
    padding-top: 12px;
}
.mvnhfhf-kjghgh {
    margin: 0!important;
    font-size: 16px!important;
}
.merchant-hfgshs  .hfgfhfh-mvhfgf {
    width: 90%;
    margin: 0 auto;
}
.merchant-hfgshs {
    box-sizing: border-box;
    padding-bottom: 20px;
}
.n-j-g {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 300px;
}
.on-tuh,.on-trzct {
    overflow-y: scroll;
}
.on-plat-hrd {
    background-image: linear-gradient(var(--sweetPinky),var(--sheetBlue));
    padding: 5px 0;
    position: sticky;
    top: 0;
    z-index: 1;
}
.on-plat-hrd h3 {
    margin: 0;
}
.bcbh-ghgh {
    position: relative;
}
.hdh-ped-jdj {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 100px;
}
.ndh-puwxhsk {
    height: 100%;
}
.jdhs-jfhdhs {
    padding: 10px;
}
.hshsgs-jdhd-online {
    color: var(--forestGreen);
}
.hshsgs-jdhd-offline {
    color: var(--bkgRed);
}
.jdhs-ioe {
    margin-top: 10px;
}
.jshs-kdkdj {
    text-align: center;
    padding-top: 1px;
}
.jkdjs-ldkdj,.ksks-ldkdj {
    width: 300px;
}
.kdjsh-lksjs {
    max-height: 500px;
    overflow-y: scroll;
}
.jkdjs-ldkdj {
    text-align: center;
    position: relative;
    margin-top: 20px!important;
}
.ksis-pwosj {
    position: absolute;
    top: 15px;
    left: 10px;
    padding: 5px;
    background-image: linear-gradient(var(--bkgColor4),var(--bkgColor5));
    padding-right: 10px;
    padding-left: 10px;
}
.ksos-pwjs {
    color: var(--white2);
    margin: 0;
}
.jshs-piels {
    width: 150px;
    position: absolute;
    right: 10px;
    top: 50px;
}
.mnshs-pwmx {
    color: var(--smartBlack);
    margin: 0;
}
.nsisod-awa {
    font-size: 28px;
}
.jdksh-f {
    padding-bottom: 30px;
}
.jdhsj-kfkd {
    text-align: right;
}
.action-modal-kdjshs {
    background-color: var(--white);
    width: 70%;
    min-height: 300px;
    position: fixed;
    top: 100px;
    left: calc((100% - 70%)/2.0);
    z-index: 5;
    padding: 5px 10px 30px 10px !important;
    text-align: center;
}
.jsjspls-pw {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 5px;
    top: 5px;
}
.snslpws {
    font-weight: 100;
}
.sjs-pwzm {
    font-size: 18px;
    font-weight: 900;
}
.mshs-kkdlkd {
    text-align: right;
    margin-top: 10px!important;
}
.kjsjskss {
    word-wrap: break-word;
    font-size: 12px;
}
.jhsg-kfjdh div h3 {
    margin: 0;
}
.jhsg-kfjdh {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30px auto;
}
.msjs-ldkjdj {
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
}
.kdjdjd-ldd {
    height: 30px;
    width: 100%;
}
.kdjdjd-ldd {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
}
.ksjshs {
    background-color: var(--grey2);
    margin-bottom: 12px !important;
}
.kkdjd {
    margin-top: 10px!important;
}
.mdjdj {
    margin-top: 10px!important;
}
.hshsg-oeoe {
    position: absolute;
    right: 10px;
    top: 10px;
}