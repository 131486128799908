/***
The Rules in Theme CSS
Mon 26 Sep 2022 15:40:22 SAST
**/

:root{
    --grey: #5d5c5c;
    --grey2:rgb(200, 195, 195);
    --bkgColor: #1E1E2E;
    --bkgColor4: #e13f54;
    --bkgColor5: #dd0923;
    --tuhTop: #2e2e56;
    --tuhBottom: #1a1a38;
    --white: #FFFFFF;
    --white2: rgb(241, 235, 235);
    --textColor: #1E1E2E;
    --inputTextColor: #433838;
    --yellowWish: #bc6201;
    --textRed: rgb(197, 9, 9);
    --textRedDark: rgb(109, 6, 6);
    --bkgRed: rgb(197, 9, 9);
    --smartBlack: #302f2f;
    --forestGreen: #208420;
    --forestGreenDark: #113f11;
    --coolBlue1: #0548b3;
    --coolBlue2: #0d3c89;
    --pinkyColor: #f4345b;
    --pinkyColorDark: #9f233c;
    --smartDarky: #0c1858;
    --smartDarky2: #131957;
    --smartDarky3: #252787;
    --smartColor1: #152272;
    --smartColor2: #071031;
    --smarColor3: #141c61;
    --smartColor4:#050d2d;
    --smartColor5: #111751;
    --smartColor6: #401db7;
    --smartColor7: #362c89;
    --smartColor8: #05c494;
    --smartColor9: #060c44;
    --sheetBlue: #020577;
    --sweetPinky: #775ec6;
    --sweetWhite: #e8eaed;
    --glass1: #383c68;
    --glass2: #474ec5;
    --rowGlassTop: rgba(222, 225, 230, 0.5);
    --rowGlassBottom: rgba(212, 214, 216, 0.5);
    --rowGlassTop2: rgba(101, 252, 245, 0.5);
    --rowGlassBottom2: rgba(103, 255, 255, 0.5);
}
.bdr-wht {
    border: 1px solid var(--sweetWhite);
}
.force-round-btn {
    border-radius: 2rem!important;
}
.padd-10 {
    padding: 10px;
}
.padd-5 {
    padding: 5px;
}
.brd-btm-grey {
    border-bottom: 1px solid var(--grey2);
}
.mgn-0 {
    margin: 0;
}
.content-flex-center,.content-centre,.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
body p {
    color: var(--textColor);
}
.tuh-tile-bkg {
    background-image: url('../assets/backgrounds/topuphome_triped_word.png');
    background-repeat: repeat;
    background-size: 100px 100px;
}
.box-b {
    box-sizing: border-box;
}
.rounded {
    border-radius: 1rem;
}
.rounded-more {
    border-radius: 2rem;
}
.pointer,.ptr {
    cursor: pointer;
}
.centered,.centred {
    margin: 0 auto;
}
.width-100,.w-100 {
    width: 100%;
}
.width-95,.w-95 {
    width: 95%;
}
.width-90,.w-90 {
    width: 90%;
}
.width-80,.w-80 {
    width: 80%;
}
.width-70,.w-70 {
    width: 70%;
}
.width-60,.w-60 {
    width: 60%;
}
.width-50,.w-50 {
    width: 50%;
}
.width-40,.w-40 {
    width: 40%;
}
.width-30,.w-30 {
    width: 30%;
}
.width-20,.w-20 {
    width: 20%;
}
.shadow {
    box-shadow:0 10px 50px rgba(0,0,0,.1);
}
.shadow-big {
    box-shadow: 0 10px 20px #e8eaed;
}
.content-right {
    text-align: right;
}
.pointer {
    cursor: pointer;
}
.bkg-white {
    background-color: var(--white);
}
.bdr-w,.bdr-white,.bdr-wt {
    border: 1px solid var(--white);
}
.min-height {
    min-height: 300px;
}
.content-center {
    text-align: center;
}
.pad-btm-20 {
    padding-bottom: 20px!important;
}
.mgn-tp-10 {
    margin-top: 10px;
}
.smart-darky-bdr {
    border: 1px solid var(--smartDarky);
}
.no-mgn-top {
    margin-top: 0;
    padding-top: 0;
}
.rounded-top {
    border-radius: 1rem 1rem 0 0;
}
.rounded-bottom {
    border-radius: 0 0 1rem 1rem;
}
.rounded-left {
    border-radius: 1rem 0 0 1rem;
}
.rounded-right {
    border-radius: 0 1rem 1rem 0;
}
.rounded-s {
    border-radius: 6px;
}
.bcvsmshdg-margin-bottom {
    margin-bottom: 30px;
}
.sweet-white {
    background-color: var(--sweetWhite);
}
.width-100px {
    width: 300px;
}
.row-staths-COMPLETE {
    color: var(--forestGreen);
    font-weight: 900;
}
.row-staths-RUNNING {
    color: var(--yellowWish);
    font-weight: 900;
}
.row-staths-RUNNABLE {
    color: var(--coolBlue1);
    font-weight: 900;
}
.row-staths-FAILED {
    color: var(--textRed);
    font-weight: 900;
}
.hsgs-dur-un-desirable {
    color: var(--bkgColor5);
}
.hsgs-dur-desirable {
    color: var(--forestGreen);
}
.hsgs-dur-very-bad {
    color: var(--textRed);
}
.jhs-ljkd-table-header {
    background-image: linear-gradient(var(--coolBlue1),var(--coolBlue2));
    color: var(--white);
}